import React, { Component } from "react";
import logo from "../assets/pics/logo.jpg";

import {
  getDownloadUrl,
  getFreeArticles,
  listAllItemsInStorage,
} from "./getDownloadUrl";
import "./Articles.css";
class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      freeArticles: [],
    };
  }
  async componentDidMount() {
    const freeArticles = await getFreeArticles();
    this.setState({
      freeArticles: freeArticles,
    });
    console.log(this.state.freeArticles);
    freeArticles.sort();
  }

  render() {
    let freeArticlesList = this.state.freeArticles.map((article) => {
      return (
        <div className="article-container ">
          <h1 class="article-title">{article.title}</h1>
          <div className={"row article"}>
            <a href={article.downloadUrl} class="article-link">
              <i class="bi bi-file-earmark-pdf-fill"></i>
              <span>Pdf</span>
            </a>
            <br></br>
            <div className={"copyright"}>
              <h6 className={"text-left"}>
                Copyright © by Dr. Richard D. Dobbins
              </h6>
            </div>
            <a
              href={article.downloadUrl}
              className="btn btn-primary btn-large article-btn"
            >
              <i class="bi bi-bookmark"></i> FREE
            </a>
          </div>
        </div>
      );
    });
    return (
      <div className="">
        <div className={"row justify-content-center"}>
          <div
            className={
              "col-xl-12  col-lg-12  col-xs-3 col-sm-12 col-md-12 d-flex justify-content-center"
            }
          >
            <img className={"logo-landing"} src={logo} alt="" id="logo-page" />
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-12  d-flex justify-content-center"}>
            <nav className=" fixed-top navbar-light " aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a className={"bread-link"} href="home">
                    HOME
                  </a>
                </li>

                <li class="breadcrumb-item active " aria-current="page">
                  HELPING AIDS
                </li>
              </ol>
              <div className={"row"}>
                <div className={"col-6  d-flex justify-content-left"}>
                  <a href={"#logo-page"}>
                    <i class="bi bi-file-arrow-up"></i>
                  </a>
                </div>
              </div>
              <div className={"col-6  d-flex justify-content-left"}>
                <a href="#end">
                  <i class="bi bi-file-arrow-down"></i>
                </a>
              </div>
            </nav>
          </div>
        </div>

        <div className="row d-flex justify-content-center article-row articles-main">
          {freeArticlesList}
        </div>
        <div className="end" id="end"></div>
      </div>
    );
  }
}

export default Articles;
