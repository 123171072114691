import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  orderBy,
  limit,
  query,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyB6XxThxmAB8ohWhdyyBt3bkbk_JoEGzJs",
  authDomain: "drdobbinsdev.firebaseapp.com",
  projectId: "drdobbinsdev",
  storageBucket: "drdobbinsdev.appspot.com",
  messagingSenderId: "272043215741",
  appId: "1:272043215741:web:d4fc61ba1f69fe1c1d1a14",
};
const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);
const db = getFirestore();

export const getDownloadUrl = (path) => {
  getDownloadURL(ref(storage, "freeArticles/ALCOHOLISM LlIVIING WITH.pdf"))
    .then((url) => {
      console.log(url);
      return url;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getFreeArticles = async () => {
  let freeArticlesArray = [];
  const aidsRef = collection(db, "freeArticles");
  const q = query(aidsRef, orderBy("title"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    freeArticlesArray.push(doc.data());
  });
  return freeArticlesArray;
};

const addToFirestore = async (articleName, downloadUrl) => {
  // Add a new document with a generated id.
  const docRef = await addDoc(collection(db, "freeArticles"), {
    title: articleName.substring(0, articleName.length - 4),
    downloadUrl: downloadUrl,
  });
  await updateDoc(docRef, {
    article_id: docRef.id,
  });

  console.log("Document written with ID: ", docRef.id);
};

export const listAllItemsInStorage = async () => {
  const listRef = ref(storage, "freeArticles");
  listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {});
      res.items.forEach((itemRef) => {
        console.log("adding " + itemRef._location.path_);
        let downloadUrl = getDownloadURL(ref(storage, itemRef._location.path_))
          .then((url) => {
            console.log("got download url as " + url);
            let articleName = itemRef._location.path_.slice(13);
            console.log(articleName);
            console.log(url);
            addToFirestore(articleName, url);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    })
    .catch((error) => {
      console.log(error);
    });

  return null;
};
