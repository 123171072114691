import "./App.css";
import Landing from "./components/landing";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import HelpingAids from "./components/HelpingAids";

import Footer from "./components/Footer";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path={"/home"} component={Landing}></Route>
          <Route path={"/Aids"} component={HelpingAids}></Route>
        </Switch>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
