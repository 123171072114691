import React, { Component } from "react";
import "./Navigation.css";

export default class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <nav id="navbar" className="navbar  container-fluid">
        <div className="menu-icon" onClick={this.handleClick}>
          <i className={this.state.clicked ? "bi bi-x-lg" : "bi bi-list"}></i>
        </div>
        <ul
          className={this.state.clicked ? "nav-menu active" : "nav-menu "}
          onClick={this.handleClick}
        >
          <li className="nav-item">
            <a className="nav-link" href="home">
              HOME
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#mission">
              MISSION
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="Aids">
              HELPING AIDS{" "}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact">
              CONTACT
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
