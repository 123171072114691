import React, { Component } from "react";
import logo from "../assets/pics/logo.jpg";
import Mission from "./Mission";
import Contact from "./Contact";
import Navigation from "./Navigation";
import AOS from "aos";
import "aos/dist/aos.css";
class Landing extends Component {
  componentDidMount() {
    AOS.init();
  }
  render() {
    return (
      <div>
        <Navigation />

        <header className="App-header" id={"home"}>
          <section className="bgimage">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 justify-content-center">
                  <img className={"logo-landing"} src={logo} alt="" id="" />
                </div>
              </div>
              <div className="row justify-content-center landing-statement">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 justify-content-center">
                  <h1>DOC DOBBINS TEACHINGS CONTINUE</h1>
                  <p
                    data-aos="zoom-in"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="800"
                    data-aos-once="true"
                  >
                    Due to many requests we are bringing you Doc’s teaching that
                    have been recovered from all his computers. “A Precious Gold
                    Mine of Wisdom,” like only Doc can provide. We have received
                    phone calls throughout the US and from other countries for
                    his teachings and asking us NOT to keep them dormant. So, to
                    begin with we are starting with 'free helping aids' that
                    were found in Richard’s writings. We have categorized
                    teachings in the form of articles coming up soon to assist
                    pastors with their counselling needs.
                  </p>
                  <p>
                    <a
                      href="Aids"
                      className="btn btn-primary btn-large home-btn"
                    >
                      See Helping Aids »
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </header>
        <Mission />
        <Contact />
      </div>
    );
  }
}

export default Landing;
