import React from "react";
import logo from "../assets/pics/logo.jpg";
import "./Footer.css";
const Footer = () => {
  return (
    <footer id="footer" className="app-footer">
      <div className="container-fluid">
        <div className={"row justify-content-center"}>
          <div
            className={
              "col-xl-12  col-lg-12  col-xs-3 col-sm-12 col-md-12 d-flex justify-content-center"
            }
          >
            <img
              className={"logo-landing"}
              src={logo}
              alt=""
              id="logo-footer"
            />
          </div>
        </div>
        <div className={"row justify-content-center"}>
          <div
            className={
              "col-xl-4  col-lg-4  col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center"
            }
          >
            <div className="footer-links">
              <div className="widget no-box links">
                <h5 className="widget-title text-center">
                  Useful Links
                  <span />
                </h5>
                <ul className="thumbnail-widget text-center">
                  <p>
                    <a className={"foot-links"} href="home">
                      Home
                    </a>
                  </p>
                  {/* <p>
                    <a className={"foot-links"} href="#contact">
                      Contact
                    </a>
                  </p>
                  <p>
                    <a className={"foot-links"} href="home">
                      Mission
                    </a>
                  </p> */}
                  <p>
                    <a className={"foot-links"} href="Aids">
                      Helping Aids
                    </a>
                  </p>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={
              "col-xl-4  col-lg-12  col-xs-12 col-sm-12 col-md-12 d-flex justify-content-center"
            }
          >
            <p className={"footer-verse"}>
              Mark 16:15-16 “And then he told them, “Go into all the world and
              preach the Good News to everyone. Anyone who believes and is
              baptized will be saved. But anyone who refuses to believe will be
              condemned.” (ESV)
            </p>
          </div>
          <div className=" col-xl-4  col-lg-12  col-xs-3 col-sm-12 col-md-12 d-flex justify-content-center">
            <div className="widget no-box links">
              <h5 className="widget-title text-center">
                Contact
                <span />
              </h5>

              <a
                href="mailto:drricharddobbins@gmail.com"
                className=" col d-flex justify-content-center fas fa-envelope text-center"
              >
                <i class="bi bi-envelope"></i>{" "}
              </a>

              <a
                href={"tel:+13307155127"}
                className="  col d-flex justify-content-center  fas fa-phone  text-center"
              >
                <i class="bi bi-telephone"></i>{" "}
              </a>

              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <h6 className="copyright text-center">
                © 2021 DRDOBBINSTEACHINGS: All Rights Reserved.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
