import React from "react";
import "./Contact.css";
const Contact = () => {
  return (
    <div className="contact-container component-top-pad " id={"contact"}>
      <div className="header-sec">
        <h2> GET IN TOUCH </h2>
        <div className="section-title mt-3"></div>
        <br></br>
        <p>If you have any questions, please call or email</p>
      </div>
      <div className="contact-sec">
        <p>drricharddobbins@gmail.com</p>
        <p>330.715.5127</p>
      </div>
    </div>
  );
};

export default Contact;
