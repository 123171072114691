import React, { useEffect } from "react";
import dobbins from "../assets/pics/dobbins.jpg";
import "./Mission.css";
import AOS from "aos";
import "aos/dist/aos.css";
const Mission = () => {
  useEffect(() => {
    AOS.init();
    window.addEventListener("load", AOS.refresh);
  });
  return (
    <div className={"container "} id="mission">
      <div className="row mission-row ">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12  ">
          <h1 className={"headings"}>MISSION STATEMENT</h1>
          <p className={"mission-into"}>
            Dr. Richard D. Dobbins was a pioneer in Christian Counseling, this
            website creates and will market throughout the world the Biblical
            teachings of Dr. Dobbins for enabling and equipping pastors/people
            to:{" "}
          </p>
          <ul
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="800"
            data-aos-once="true"
            className="mission-list"
          >
            <li>
              <p>Understand more clearly the spiritual nature of thinking.</p>
            </li>
            <li>
              <p>
                Enable people to make the supernatural practical in their daily
                lives.
              </p>
            </li>

            <li>
              <p>Build strong and healthy marriages.</p>
            </li>

            <li>
              <p>
                {" "}
                For parents to be the primary sex educators of their children.
              </p>
            </li>

            <li>
              <p>
                Integrate faith and science into the mental health process that
                enables people to utilize their faith in God as a dynamic part
                of the healing process.
              </p>
            </li>
          </ul>
        </div>
        <div className="col-xl-6 col-lg-6  col-lg-offset-1 col-md-12 col-sm-12 col-xs-12  ">
          <img alt="" className={"img-fluid"} src={dobbins} />
        </div>
      </div>
      <br />
    </div>
  );
};

export default Mission;
